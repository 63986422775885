import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElementInhoudInterface, ElementInterface } from '../../../app/core/interfaces';
import { RequestService } from '../../../app/core/request/request.service';
import { MileageCarEnum } from '../../../app/salesfunnel/enums/mileage-car.enum';

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
@Injectable({
    providedIn: 'root',
})
export class ElementsService {

    constructor(
        private request: RequestService
    ) { }

    public getElement$(elementName: string): Observable<ElementInterface> {
        return this.getElements$(elementName).pipe(
            map((result): ElementInterface => result.elementen.pop())
        );
    }

    public getElementContent$(elementName: string): Observable<ElementInhoudInterface[]> {
        return this.getElement$(elementName).pipe(
            map((element): ElementInhoudInterface[] => element?.inhoud),
            map((inhoud): ElementInhoudInterface[] => {
                // TODO: uitzoeken of deze specifieke uitzonderingen in deze generieke service thuishoren
                if (elementName !== 'kilometrage_auto') {
                    return inhoud;
                }

                // TODO: uitzoeken of deze specifieke uitzonderingen in deze generieke service thuishoren
                return inhoud.filter((id): boolean => [
                    MileageCarEnum.LessThen15000.toString(),
                    MileageCarEnum.Between15000And25000.toString(),
                    MileageCarEnum.MoreThen25000.toString(),
                ].includes(id.inhoud_id));
            })
        );
    }

    private getElements$(elementName: string): Observable<{ elementen: ElementInterface[] }> {
        const payload = {
            stuurcode: null,
            elementen: {
                item: []
            }
        };

        payload.elementen.item.push({
            element_id: null,
            element_naam: elementName,
            context_id: null,
            groep_inhoud_id: null,
        });

        return this.request.post('formulier-algemeen/opvragen/element', payload).pipe(
            map((result): { elementen: ElementInterface[] } => result.data)
        );
    }
}
